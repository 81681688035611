<template>
  <div class="login" v-if="!isProcessing">
    <img class="login__logo" :src="require('@/assets/img/logo.png')" contain />
    <h2 class ="login__title">管理者画面</h2>
    <login-sns-form class="login__sns" />
  </div>
</template>

<script>
import LoginSnsForm from '@/components/login/form/sns.vue'

export default {
  name: 'login',
  components: { LoginSnsForm },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';

.login {
  width: 100vw;
  min-height: 100vh;
  text-align: center;
  &__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 73%;
    max-width: 200px;
    height: 130px;
    margin: 50px auto 0 auto;
    object-fit: contain;
  }
  &__title {
    margin-top: 30px;
    font-size: 18px;
  }
  &__sns {
    margin: 30px auto 0 auto;
  }
}
</style>

<template>
  <div class="sns">
    <v-btn depressed class="sns__btn" @click="login()">
      <v-icon class="sns__btn__icon">fab fa-google</v-icon>
      Googleでログイン
    </v-btn>
  </div>
</template>

<script>

export default {
  methods: {
    /**
     * ログイン
     */
    async login () {
      this.$store.commit('setProcessing', true)
      const user = await this.$store.dispatch('auth/signin', 'google')
      // ユーザーがいる場合はホームへ、いない場合は処理中を解除
      user ? this.$router.push({ name: 'home' }) : this.$store.commit('setProcessing', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';

.sns {
  position: relative;
  width: 73%;
  max-width: 300px;
  text-align: center;
  &__btn {
    width: 100%;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.6rem;
    color: $white_color;
    text-transform: none;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round) {
      height: 40px;
    }
    &.theme--light.v-btn:not(.v-btn--flat) {
      background-color: rgba(#dd4b39, 0.7);
    }
    &__icon {
      margin-right: 10px;
      font-size: 2rem;
    }
  }
}
</style>
